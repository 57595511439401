import React from 'react'
import ToggleInput from '../ToggleInput/ToggleInput'
import { get } from '../../utils/get-prop'

const LevtidOnlineInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const currentValue = get(details, `${solution.key}.${questionKey}`)

  return (
    <div style={{ textAlign: 'center' }}>
      <ToggleInput
        value={currentValue}
        onClick={newValue => {
          onDetailsChange(solution.key, questionKey, newValue)
        }}
      />
    </div>
  )
}

export default LevtidOnlineInput
