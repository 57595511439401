import React from 'react'
import { get } from '../../utils/get-prop'
import Slider from '../Slider/Slider'

const ReturnPercentageInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const value = get(details, `${solution.key}.${questionKey}`, 5)
  const min = 0
  const max = 100
  const step = 1
  const unit = '%'

  return (
    <Slider
      min={min}
      max={max}
      unit={unit}
      step={step}
      value={value}
      onChange={val => {
        onDetailsChange(solution.key, questionKey, val)
      }}
    />
  )
}

export default ReturnPercentageInput
