import React from 'react'
import MaterialSlider from '@material-ui/lab/Slider'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import './Slider.css'

const theme = createMuiTheme({
  overrides: {
    MuiSlider: {
      // override root styles for the button component. https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Slider/Slider.js
      track: {
        backgroundColor: '#C22A23',
        borderRadius: 2.5,
        height: 5,
        marginTop: 17
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        width: 21,
        height: 21,
        marginTop: 17,
        boxShadow: '2px 3px 5px 2px rgba(171, 171, 171, 0.32)',
        '&$focused, &:hover': {
          boxShadow: '0px 2px 4px 0px rgba(171, 171, 171, 0.32)'
        },
        '&$activated': {
          boxShadow: '2px 2px 4px 2px rgba(171, 171, 171, 0.32)'
        },
        '&$jumped': {
          boxShadow: '1px 2px 4px 1px rgba(171, 171, 171, 0.32)'
        }
      }
    }
  }
})

const Slider = ({ min, max, step, value, onChange, unit }) => {
  return (
    <div className="slider">
      <div className="plusMinusWrapper">
        <button
          onClick={() => {
            onChange(value - 1)
          }}
        >
          <div className="sliderMinus" />
        </button>
        <div className="average-weight">
          {value}
          <span className="average-weight-kg">{unit}</span>
        </div>
        <button
          onClick={() => {
            onChange(value + 1)
          }}
        >
          <div className="sliderPlus" />
        </button>
      </div>
      <MuiThemeProvider theme={theme}>
        <MaterialSlider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e, val) => {
            onChange(val)
          }}
        />
      </MuiThemeProvider>
    </div>
  )
}

export default Slider
