import React from 'react'

const NextStepButton = ({ nextStep, history, disabled, isHidden }) => {
  const style = isHidden
    ? {
        opacity: 0,
        pointerEvents: 'none'
      }
    : disabled
      ? {
          opacity: '0.5',
          pointerEvents: 'none'
        }
      : {}

  return (
    <div
      style={style}
      className="submitButton"
      onClick={e => {
        e.preventDefault()
        if (disabled) return false
        history.push(nextStep.path)
      }}
    >
      <p className="submitButton-text">Nästa</p>
    </div>
  )
}

export default NextStepButton
