import React from 'react'
import { get } from '../../utils/get-prop'
import Slider from '../Slider/Slider'

const PercentagePalletsInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details,
  min = 0,
  max = 1500,
  step = 1,
  unit = ' st'
}) => {
  const value = get(details, `${solution.key}.${questionKey}`, 500)

  return (
      <Slider
        min={min}
        max={max}
        unit={unit}
        step={step}
        value={value}
        onChange={val => {
          onDetailsChange(solution.key, questionKey, val)
        }}
      />
  )
}

export default PercentagePalletsInput
