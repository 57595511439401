import solutions from '../data/solutions.json'

function getSolution(key) {
  const res = solutions.find(el => {
    return el.key === key
  })
  return res
}

export default getSolution
