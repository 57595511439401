import React from 'react'
import './Footer.css'
import GoBackButton from '../GoBackButton/GoBackButton'
import Step from '../Step/Step'
import SubmitButton from '../SubmitButton/SubmitButton'
import NextStepButton from '../NextStepButton/NextStepButton'

import {
  getSteps,
  getCurrentStep,
  getNextStep,
  getPreviousStep,
  getFirstDetailStep
} from '../../utils/steps'
import FooterSolutions from '../FooterSolutions/FooterSolutions'

const shouldShowProductsFooter = currentStep => {
  if (!currentStep) return false
  if (!currentStep.key) return false
  return (
    currentStep.key === 'products' || currentStep.key.startsWith('details')
  )
}

const Footer = ({
  onAddSolution,
  filters,
  selectedSolutions = [],
  location,
  history
}) => {
  const { pathname } = location

  const steps = getSteps(selectedSolutions, pathname)
  const nextStep = getNextStep(selectedSolutions, pathname)
  const previousStep = getPreviousStep(selectedSolutions, pathname)
  const currentStep = getCurrentStep(selectedSolutions, pathname)
  const firstDetailStep = getFirstDetailStep(selectedSolutions)

  const showProductsFooter = shouldShowProductsFooter(currentStep)
  const detailsStep =
    currentStep && currentStep.key.startsWith('details')
      ? currentStep.key.replace('details/', '')
      : false

  const hideButtons = pathname === '/new'

  return (
    <React.Fragment>
      {showProductsFooter &&
        <FooterSolutions
          onAddSolution={onAddSolution}
          filters={filters}
          selectedSolutions={selectedSolutions}
          detailsStep={detailsStep}
        />}

      <footer className="app-footer">
        <GoBackButton isHidden={hideButtons} previousStep={previousStep} history={history} />

        <Step
          steps={steps}
          firstDetailStep={firstDetailStep}
          pathname={pathname}
          history={history}
        />

        {nextStep
          ? <NextStepButton
              isHidden={hideButtons}
              nextStep={nextStep}
              history={history}
              disabled={!selectedSolutions.length}
            />
          : <SubmitButton />}
      </footer>
      <div style={{ height: showProductsFooter ? 200 : 80 }} />
    </React.Fragment>
  )
}

export default Footer
