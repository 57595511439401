import React from 'react'
import cn from 'classnames'
import './RedButton.css'

const RedButton = ({ children, className, type = 'submit', ...extraProps }) =>
  <button type={type} className={cn('red-button', className)} {...extraProps}>
    {children}
  </button>

export default RedButton
