import React from 'react'
import './PrintableSummary.css'
import { Row, Col } from 'react-simple-flex-grid'

import getSolution from '../../utils/getSolution'
import getShippingItem from '../../utils/getShippingItem'
import getMarket from '../../utils/getMarket'
import getRecipient from '../../utils/getRecipient'
import getExtra from '../../utils/getExtra'
import getDetailValue from '../../utils/getDetailValue'

import logo from '../../assets/Icons/DHLLogo.svg'

import EuroconnectSummary from '../EuroconnectSummary/EuroconnectSummary'

const printTime = (from, to) => {
  const f = from ? from[0] : '07:00'
  const t = to ? to[0] : '17:00'

  return `${f} - ${t}`
}

const PrintDefinitionList = ({ items }) =>
  <dl className="print-definition-list">
    {items.map((item, n) =>
      <Row key={n} gutter={0}>
        <Col span={6}>
          <dt className="print-definition-list__term">
            {item.key}
          </dt>
        </Col>
        <Col span={6}>
          <dd className="print-definition-list__definition">
            {item.value}
            &nbsp;
          </dd>
        </Col>
      </Row>
    )}
  </dl>

const PrintHeadlineLarge = ({ style, children }) =>
  <h1 className="print-headline print-headline--large" style={style}>
    {children}
  </h1>
const PrintHeadline = ({ children, style = {} }) =>
  <h2 className="print-headline" style={style}>
    {children}
  </h2>

const PrintParagraph = ({ style, children }) =>
  <p style={style} className="print-paragraph">
    {children}
  </p>

const PrintHeader = ({ date }) =>
  <div className="print-header">
    <Row>
      <Col span={6}>
        <img src={logo} alt="DHL" />
      </Col>
      <Col span={6}>
        <div style={{ textAlign: 'right', color: '#c22a23', paddingTop: 5 }}>{date}</div>
      </Col>
    </Row>
  </div>

const PrintUserCard = ({ companyName, name, title, email, phone }) =>
  <div className="print-user-card">
    {companyName && (
      <div className="print-user-card__company-name">
        {companyName}
      </div>    
    )}
    <div className="print-user-card__name">
      {name}
    </div>
    <div className="print-user-card__title">
      {title}
    </div>
    <div className="print-user-card__email">
      {email}
    </div>
    <div className="print-user-card__phone">
      {phone}
    </div>
  </div>

const PrintSummary = ({ customer, user }) =>
  <div className="">
    <PrintParagraph style={{ paddingBottom: 20 }}>
      I detta dokument beskrivs de produkter som passar era transportbehov bäst, anpassade efter era önskemål och med valda tilläggstjänster.
    </PrintParagraph>

    <PrintHeadline style={{ 
      borderBottom: '1px solid rgb(184, 184, 184)',
      paddingBottom: 10

    }}>Säljare</PrintHeadline>

    <PrintUserCard
      name={user.name}
      title={user.title}
      email={user.email}
      phone={user.phone}
    />

    <PrintUserCard
      companyName={customer.name}
      name={customer.contactPerson}
      title={customer.title}
      email={customer.email}
      phone={customer.phone}
    />
  </div>

const PrintDetails = ({ details, filters }) => {
  const {
    shippingItems = [],
    recipients = [],
    markets = [],
    
    openingHoursFrom, 
    openingHoursTo,
    pickupTimesFrom,
    pickupTimesTo

  } = filters
  const recipientsStr = recipients
    .map(getRecipient)
    .map(e => e.value)
    .join(', ')
  const marketsStr = markets.map(getMarket).map(e => e.value).join(', ')
  const shippingItemsStr = shippingItems
    .map(getShippingItem)
    .map(e => e.value)
    .join(', ')

  const generalInfo = details && details.general && details.general.freetextInfo

  return (
    <div className="print-details">
      <PrintHeadline>Transportbehov</PrintHeadline>
      <PrintDefinitionList
        items={[
          { key: 'Öppettider', value: printTime(openingHoursFrom, openingHoursTo) },
          { key: 'Hämtningstider', value: printTime(pickupTimesFrom, pickupTimesTo) },
          { key: 'Mottagare', value: recipientsStr },
          { key: 'Marknader', value: marketsStr },
          { key: 'Godstyp', value: shippingItemsStr }
        ]}
      />
      {generalInfo && (
        <React.Fragment>
          <PrintHeadline style={{ paddingTop: 20 }}>Övriga kommentarer</PrintHeadline>
          <PrintParagraph>
            {generalInfo}
          </PrintParagraph>
        </React.Fragment>
      )}
    </div>
  )
}

const PrintProducts = ({ customerName, details, selectedSolutions, selectedExtras, date }) => {
  const solutions = selectedSolutions.map(solutionKey => {
    const solution = getSolution(solutionKey)
    return solution
  })

  return (
    <div className="print-products">
      {solutions.map(s => {
        const extras =
          selectedExtras && Array.isArray(selectedExtras[s.key])
            ? selectedExtras[s.key]
            : []

        return (
          <div key={s.key}>

            {(s.key === 'parcel-connect' || s.key === 'paket-export' || s.key === 'euroconnect') && (
              <div>
                <div className="print-product-sheet">
                  <PrintHeader date={date} />
                  <section className="print-page">
                    <PrintHeadline>{s.name}</PrintHeadline>
                    <EuroconnectSummary solution={s} details={details} />
                  </section>
                </div>
              </div>
            )}

            <div className="print-product-sheet">
            <PrintHeader date={date} />
            <section className="print-page">
              <PrintHeadline>{s.name}</PrintHeadline>

              {s.questionSections.map(qs =>
                <table key={qs.key}>
                  <thead>
                    <tr>
                      <th colSpan={2} className="print-product-sheet__question-section-headline">
                        {qs.title}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {qs.questions.map(q =>{

                      // Do not print euroconnect info in the table
                      if (q.key === 'number-of-shipments-euroconnect') {
                        return null
                      } 

                      return (
                        <tr key={q.key}>
                          <th>
                            {(q.shortTitle || q.title).replace('$customerName', customerName)}
                          </th>
                          <td>
                            {getDetailValue(details, s.key, q.key)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}

              {selectedExtras &&
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2} className="print-product-sheet__question-section-headline">
                        Tillval
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Valda tillval</th>
                      <td>
                        {extras.map(extraKey => {
                          const extra = getExtra(extraKey)
                          return (
                            <div key={extraKey} className="summary-table__extra">
                              {extra ? extra.title : extraKey}
                            </div>
                          )
                        })}
                        {extras.length === 0 ? (
                          <span className="default-value">Inga valda</span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>}
            </section>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const PrintableSummary = ({
  selectedSolutions,
  customer,
  user,
  filters,
  details,
  selectedExtras
}) => {
  const y = (new Date()).getFullYear()
  const m = (new Date()).getMonth() + 1
  const d = (new Date()).getDate()

  const date = `${y}-${m > 9 ? m : `0${m}`}-${d > 9 ? d : `0${d}`}`
  document.title = `${customer.name || ''} - ${date}`
  return (
    <div className="summary-view summary-view--print">
      <PrintHeader date={date} />
      <PrintHeadlineLarge style={{ marginBottom: 0 }}>Sammanfattning</PrintHeadlineLarge>

      <div className="print-page">
        <PrintSummary customer={customer} user={user} />
        <PrintDetails filters={filters} details={details} />
      </div>
      <div style={{ clear: 'both '}}>
        <PrintProducts
          date={date}
          customerName={customer.name}
          selectedSolutions={selectedSolutions}
          details={details}
          selectedExtras={selectedExtras}
          />
      </div>
    </div>
  )
}

export default PrintableSummary
