/* eslint react/jsx-no-target-blank: 0 */

import React from 'react'
import './ExtraProduct.css'
import cn from 'classnames'
import skickaGrontPdf from '../../assets/pdf/skickagront.pdf'

const ExtraProduct = ({ solution, extra, onAddExtras, isSelected }) =>
  <div
    style={{ position: 'relative' }}
    className={cn('extra-product', {
      'extra-product--selected': isSelected
    })}
    onClick={e => {
      onAddExtras(solution.key, extra.key)
    }}
  >
    <div className="extra-product__plus-icon">+</div>
    <span className="extra-product__title">
      {extra.title}
    </span>
    {extra.key === 'skicka-gront' && (
      <a
        href={skickaGrontPdf}
        style={{ top: 6, right: 35 }}
        target="_blank"
        className="footer-solution-item__info-link"
      >
        Download
      </a>
    )}
  </div>

export default ExtraProduct
