import React from 'react'
import './BackgroundImage.css'

const BackgroundImage = ({ children }) =>
  <div className="background-image-wrapper">
    <div className="background-image"></div>
    {children}
  </div>

export default BackgroundImage
