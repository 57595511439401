import allProductTypes from '../data/product-types-input-data.json'
import allFreightTypes from '../data/freight-types.json'

const printPeakSeason = value => {
  const seasons = ['Vår', 'Sommar', 'Höst', 'Vinter']
  return seasons
    .map((season, i) => {
      if (value[i] && value[i] > 50) return season
      else return null
    })
    .filter(Boolean)
    .join(', ')
}

const printFreightTypes = value => {
  return value
    .map(ftKey => {
      const ft = allFreightTypes.find(freightType => freightType.key === ftKey)
      return ft.value
    })
    .join(', ')
}

const printProductTypes = (value, details, _, solution) => {
  const questionDetails = details[solution.key]

  return value
    .map(productTypeKey => {
      const productType = allProductTypes.find(pt => pt.key === productTypeKey)

      if (productType.extraInfo) {
        const extraInfo = productType.extraInfo[0] // SMELL: Handle more values in the future?
        const extraInfoKey = `product-types:${productTypeKey}:${extraInfo.key}`

        if (questionDetails[extraInfoKey]) {
          return `${productType.value}: ${extraInfo.value}`
        }
      }

      return productType.value
    })
    .join(', ')
}

const printProductValue = value => {
  if (value === 'low') return 'Lågt'
  if (value === 'medium') return 'Normalt'
  if (value === 'high') return 'Högt'
  return 'Ej specificierat'
}

const printUnloadingProcedure = value => {
  return value ? 'Normalt' : 'Begränsad yta/portar'
}

const printHalvpallHellpall = value => {
  const halvpallValue = 100 - value
  const values = [value, halvpallValue]
  return values.map(value => `${value}%`).join(' / ')
}

const printPreparedForShipment = value => {
  return value ? 'Ja' : 'Nej'
}

const printExtraCapacity = value => {
  return value ? 'Ja' : 'Nej'
}

const printLevtidOnline = value => {
  return value ? 'Nej' : 'Ja'
}

const renderFunctions = {
  printPeakSeason,
  printFreightTypes,
  printProductTypes,
  printProductValue,
  printUnloadingProcedure,
  printHalvpallHellpall,
  printPreparedForShipment,
  printExtraCapacity,
  printLevtidOnline
}

export default renderFunctions
