import React from 'react'
import './EuroconnectSummary.css'
import solutions from '../../data/solutions.json'
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


const printValue = (value) => {
  if (typeof value === 'undefined') {
    return <span style={{ color: '#999' }}>0</span>
  }

  return value
}

const regionHasData = (region, data) => {
  console.log(region, data)
  return true
} 


const RegionTable = ({ countries, regionKey, data, hideImport }) => {
  return (
    <table className="ec-summary-table">
      <thead>
        <tr>
          <th>{capitalize(regionKey)}</th>
          {!hideImport && <th style={{ width: 50 }}>Imp</th>}
          <th style={{ width: 50 }}>Exp</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(countries).map(([country], n) => {
          // const value 
          'number-of-shipments-euroconnect-belgien-export'
          const imp = data[`number-of-shipments-euroconnect-${country}-import`]
          const exp = data[`number-of-shipments-euroconnect-${country}-export`]
          return (
            <tr key={n}>
              <td>{capitalize(country)}</td>
              {!hideImport && <td style={{ width: 50 }} className="ec-summary-table__value">{printValue(imp)}</td>}
              <td style={{ width: 50 }} className="ec-summary-table__value">{printValue(exp)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const EuroconnectSummary = ({ solution, details = {} }) => {
  const qs = solution.questionSections.find(qs => qs.key === 'volume')
  const q = qs.questions.find(q => q.key === 'number-of-shipments-euroconnect')
  const defaultData = q.defaultValue

  const baseSolution = solutions.find(s => s.key === solution.key)
  const bQs = baseSolution.questionSections.find(qs => qs.key === 'volume')
  const baseQuestion = bQs.questions.find(q => q.key === 'number-of-shipments-euroconnect')
  const baseQuestionProps = baseQuestion.props || {}
  const { hideImport } = baseQuestionProps

  const data = details[solution.key] || {}

  return (
  <div className="ec-summary">
    <div className="ec-summary__column">
      {defaultData.norden && regionHasData('norden', data) && (
        <RegionTable 
          data={data}
          regionKey="norden"
          countries={defaultData.norden}
          hideImport={hideImport}
          />
      )}

      {defaultData['västeuropa'] && regionHasData('västeuropa', data) && (
        <RegionTable 
        data={data}
        regionKey="västeuropa"
        countries={defaultData['västeuropa']}
        hideImport={hideImport}
        />
      )}
      {defaultData['cis'] && regionHasData('cis', data) && (
        <RegionTable 
          data={data}
          regionKey="cis"
          countries={defaultData.cis}
          hideImport={hideImport}
          />
      )}
    </div>
    <div className="ec-summary__column">
      {defaultData['centraleuropa'] && regionHasData('centraleuropa', data) && (
        <RegionTable 
          data={data}
          regionKey="centraleuropa"
          countries={defaultData.centraleuropa}
          hideImport={hideImport}
          />
      )}
      {defaultData['baltikum'] && regionHasData('baltikum', data) && (
        <RegionTable 
          data={data}
          regionKey="baltikum"
          countries={defaultData.baltikum}
          hideImport={hideImport}
          />
      )}
    </div>
  </div>
)}

export default EuroconnectSummary