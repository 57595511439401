import React from 'react'
import { get } from '../../utils/get-prop'
import Slider from '../Slider/Slider'

const AverageWeightInput = ({
  solution,
  questionKey,
  onDetailsChange,
  max = 20,
  initialValue = 10,
  unit = ' kg',
  step = 0.5,
  min = 0,
  details
}) => {
  const value = get(details, `${solution.key}.${questionKey}`, initialValue)

  return (
    <Slider
      min={min}
      max={max}
      step={step}
      value={value}
      unit={unit}
      onChange={val => {
        onDetailsChange(solution.key, questionKey, val)
      }}
    />
  )
}

export default AverageWeightInput
