import React from 'react'

import Underline from '../../components/Underline/Underline'
import MediumDivider from '../../components/MediumDivider/MediumDivider'
import SmallDivider from '../../components/SmallDivider/SmallDivider'
import HeadlineMega from '../../components/HeadlineMega/HeadlineMega'
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
import CustomerDetailsCard from '../../components/CustomerDetailsCard/CustomerDetailsCard'
import Appear from '../../components/Appear/Appear'

const NewCustomer = ({ customer, onResetForm, onUpdateCustomerDetails }) =>
  <BackgroundImage>
    <Appear>
      <SmallDivider />
      <HeadlineMega>Skräddarsydda produkter</HeadlineMega>

      <SmallDivider />
      <Underline />
      <MediumDivider />
      <div style={{ maxWidth: 462, margin: '0 auto' }}>
        <CustomerDetailsCard
          onResetForm={onResetForm}
          onUpdateCustomerDetails={onUpdateCustomerDetails}
          customer={customer}
        />
      </div>
      <SmallDivider />
    </Appear>
  </BackgroundImage>

export default NewCustomer
