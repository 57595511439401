import React, { Component } from 'react'
import url from 'url'
import querystring from 'querystring'
import 'normalize.css'
import 'react-simple-flex-grid/lib/main.css'
import './App.css'
import './styles/fonts.css'
import './styles/scrollbar.css'
import './App.css'
import './styles/print.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from './utils/scrollToTop'

import SignIn from './pages/SignIn/SignIn'
import NewCustomer from './pages/NewCustomer/NewCustomer'
import Products from './pages/Products/Products'
import Details from './pages/Details/Details'
import Summary from './pages/Summary/Summary'
import Start from './pages/Start/Start'

import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import ContactCard from './components/ContactCard/ContactCard'

import solutions from './data/solutions.json'
import getSolution from './utils/getSolution'
import solutionMatchesFilterCheck from './utils/solutionMatchesFilterCheck'

import { withUser } from './utils/get-user'

const emptyState = {
  customer: {},
  filters: {},
  details: {},
  selectedSolutions: [],
  selectedExtras: {}
}

const containItems = array => {
  return Array.isArray(array) && array.length > 0
}

class App extends Component {
  setStateLs = (val, cb) => {
    this.setState(val, () => {
      const dataObject = {
        ...this.state,
        ...val
      }
      const data = JSON.stringify(dataObject)
      // console.log('Writing this to LS:', dataObject.details)
      localStorage.setItem('state', data)
      if (typeof cb === 'function') {
        cb()
      }
    })
  };

  constructor(props) {
    super(props)
    let state = JSON.parse(localStorage.getItem('state'))
    if (!state) {
      state = {}
    }
    if (!state.filters) {
      state.filters = {
        recipients: [],
        markets: [],
        shippingItems: []
      }
    }
    this.state = state
  }

  onSignOut = () => {
    this.setStateLs({
      ...emptyState
    })
    window.localStorage.removeItem('token')
    window.location.reload()
  };

  onResetForm = () => {
    this.setStateLs({
      ...emptyState
    })
  };

  onUpdateCustomerDetails = customer => {
    this.setStateLs({ customer })
  };

  onAddFilter = (filterKey, filterValue, replace = false) => {
    let { selectedSolutions } = this.state
    const { filters } = this.state

    if (replace || !Array.isArray(filters[filterKey])) {
      filters[filterKey] = [filterValue]
    } else {
      if (filters[filterKey].includes(filterValue)) {
        filters[filterKey].splice(filters[filterKey].indexOf(filterValue), 1)
      } else {
        filters[filterKey].push(filterValue)
      }
    }

    if (containItems(filters.recipients) && containItems(filters.markets) && containItems(filters.shippingItems)) {
      selectedSolutions = []
      solutions.forEach(solution => {
        if (solutionMatchesFilterCheck(filters, solution)) {
          selectedSolutions.push(solution.key)
        }
      })
    }

    this.setStateLs({ filters, selectedSolutions })
  };

  onAddSolution = solutionKey => {
    let { selectedSolutions } = this.state

    if (!Array.isArray(selectedSolutions)) {
      selectedSolutions = [solutionKey]
    } else {
      if (selectedSolutions.includes(solutionKey)) {
        selectedSolutions.splice(selectedSolutions.indexOf(solutionKey), 1)
      } else {
        selectedSolutions.push(solutionKey)
      }
    }

    selectedSolutions.sort((a, b) => {
      const solutionA = getSolution(a)
      const solutionB = getSolution(b)

      return solutionA.order - solutionB.order
    })

    this.setStateLs({ selectedSolutions })
  };

  onAddExtras = (solutionKey, extraKey) => {
    let { selectedExtras = {} } = this.state

    if (!Array.isArray(selectedExtras[solutionKey])) {
      selectedExtras[solutionKey] = [extraKey]
    } else {
      if (selectedExtras[solutionKey].includes(extraKey)) {
        selectedExtras[solutionKey].splice(
          selectedExtras[solutionKey].indexOf(extraKey),
          1
        )
      } else {
        selectedExtras[solutionKey].push(extraKey)
      }
    }

    this.setStateLs({ selectedExtras })
  };

  onDetailsChange = (solutionKey, questionKey, value) => {
    let { details = {} } = this.state
    if (!details[solutionKey]) {
      details[solutionKey] = {}
    }
    // console.log('ondeatils got these values', solutionKey, questionKey, value)
    details[solutionKey][questionKey] = value
    // console.log('in ondeatils change', details)

    this.setStateLs({ details })
  };

  render() {
    const {
      customer,
      filters,
      selectedSolutions,
      details,
      selectedExtras
    } = this.state
    const { user } = this.props

    const customerName = customer ? customer.name : ''

    return (
      <Router>
        <ScrollToTop>
          <React.Fragment>
            <Navbar user={user} onSignOut={this.onSignOut} />
            {user &&
              <div className="content">
                <Switch>
                  <Route path="/products" exact
                    render={routeProps =>
                      <Products
                        {...routeProps}
                        customerName={customerName}
                        filters={filters}
                        selectedSolutions={selectedSolutions}
                        onAddSolution={this.onAddSolution}
                        onAddFilter={this.onAddFilter}
                      />}
                  />
                  <Route path="/details/:key"
                    render={routeProps =>
                      <Details
                        {...routeProps}
                        details={details}
                        customerName={customerName}
                        onDetailsChange={this.onDetailsChange}
                        onAddExtras={this.onAddExtras}
                        selectedExtras={selectedExtras}
                      />}
                  />
                  <Route path="/summary"
                    render={routeProps =>
                      <Summary
                        {...routeProps}
                        details={details}
                        customerName={customerName}
                        customer={customer}
                        user={user}
                        filters={filters}
                        selectedSolutions={selectedSolutions}
                        selectedExtras={selectedExtras}
                      />} 
                  />

                  <Route path="/new"
                    render={routeProps =>
                      <NewCustomer
                        customer={customer}
                        onResetForm={this.onResetForm}
                        onUpdateCustomerDetails={this.onUpdateCustomerDetails}
                        {...routeProps}
                      />}
                    />

                  <Route render={routeProps => <Start {...routeProps} />} />
                </Switch>
              </div>}

            {user &&
              <Route path="/new"
                render={routeProps =>
                  <ContactCard user={user} {...routeProps} />}
              />}

            {user &&
              <Route path="/(new|products|details|summary)"
                render={routeProps =>
                  <Footer
                    onAddSolution={this.onAddSolution}
                    filters={filters}
                    selectedSolutions={selectedSolutions}
                    {...routeProps}
                  />}
              />}
          </React.Fragment>
        </ScrollToTop>
      </Router>
    )
  }
}

const AppWithUser = withUser(App)

const AppWrapper = () => {
  let token = localStorage.getItem('token')
  
  if (!token) {
    const currentUrl = url.parse(window.location.href, true)
    const { token: qsToken, ...queryParams } = currentUrl.query

    if (qsToken) {
      localStorage.setItem('token', qsToken)
      
      const currentUrlString = currentUrl.href.split('?')[0]
      const queryParamsExceptToken = querystring.stringify(queryParams)
      window.location.href = `${currentUrlString}?${queryParamsExceptToken}`
      return null
    }
  }

  if (!token) {
    return (<SignIn />)
  }

  return <AppWithUser />
}

export default AppWrapper
