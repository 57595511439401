import React from 'react'
import { get } from '../../utils/get-prop'
import Slider from '../Slider/Slider'

const NumberOfPackagesInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const value = get(details, `${solution.key}.${questionKey}`, 500)
  const min = 0
  const max = 1500
  const step = 1
  const unit = 'st'

  return (
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        unit={unit}
        onChange={val => {
          onDetailsChange(solution.key, questionKey, val)
        }}
      />

  )
}

export default NumberOfPackagesInput
