import React from 'react'
import getSolution from './getSolution'
import renderFunctions from './summaryRenderFunctions'

const getAllSolutionQuestions = solution => {
  const all = []
  // eslint-disable-next-line array-callback-return
  solution.questionSections.map(qs => {
    all.push(...qs.questions)
  })
  return all
}

const getQuestion = (solution, questionKey) => {
  const questions = getAllSolutionQuestions(solution)
  return questions.find(q => q.key === questionKey)
}

function getDetailValue(details = {}, solutionKey, questionKey) {
  const solution = getSolution(solutionKey)

  const q = getQuestion(solution, questionKey) || {}
  const { defaultValue } = q 
  // console.log({ defaultValue })
  const solutionValues = details[solutionKey] || {}
  const value = solutionValues[questionKey]

  const unit = q && q.summary && q.summary.unit
  const renderFunction = q && q.summary && q.summary.renderFunction

  if (typeof value === 'undefined') {
    if (typeof defaultValue !== 'undefined') {
      const deafultValStr = typeof defaultValue === 'object' ? JSON.stringify(defaultValue): defaultValue
      return <span className="default-value">{deafultValStr}</span>
    }

    // 
    return <span style={{ color: '#999' }}>-</span>
    //  return null;
  }

  if (renderFunction) {
    return renderFunctions[renderFunction](value, details, q, solution)
  }

  if (Array.isArray(value)) {
    return value.join(', ')
  }

  const withUnit = unit ? ` ${unit}` : ''

  return `${value}${withUnit}`
}

export default getDetailValue
