import React from 'react'
import cn from 'classnames' 
import './SummaryTable.css'

import getSolution from '../../utils/getSolution'
import getExtra from '../../utils/getExtra'
import getAllQuestionSections from '../../utils/getAllQuestionSections'
import getDetailValue from '../../utils/getDetailValue'

import { withRouter } from 'react-router-dom'

const SummaryTable = ({
  details,
  customerName,
  selectedSolutions = [],
  selectedExtras = {},
  history
}) => {
  const solutions = selectedSolutions.map(solutionKey => {
    const solution = getSolution(solutionKey)
    return solution
  })

  const allQuestionSections = getAllQuestionSections()

  const solutionCount = solutions.length

  return (
    <table className="summary-table" style={{ width: 300 + (solutionCount * 200) }}>
      <thead>
        <tr className="summary-table__solutions-row">
          <th />
          {solutions.map(s =>
            <th
              key={s.key}
              className="summary-table__solution-link"
              onClick={e => {
                e.preventDefault()
                history.push(`/details/${s.key}`)
              }}
            >
              <div className="volumeCardBackground">
                <div className={cn('volumeCardIcon', s.key)} />
              </div>
              {s.name}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {allQuestionSections.map(qs =>
          <React.Fragment key={qs.key}>
            <tr className="summary-table__section-row">
              <th colSpan={solutionCount + 1}>
                {qs.title}
              </th>
            </tr>

            {qs.questions.map(q =>{
              // Do not print euroconnect info in the table
              if (q.key === 'number-of-shipments-euroconnect') {
                return null
              }

              return (
                <tr key={q.key} className="summary-table__section-row-data">
                  <td className="summary-table__title-cell">
                    {q.title.replace('$customerName', customerName)}
                  </td>
                  {solutions.map(s =>
                    <td key={s.key} className="summary-table__value-cell">
                      {getDetailValue(details, s.key, q.key)}
                    </td>
                  )}
                </tr>
              )
            })}

            <tr className="summary-table__section-row-spacer" />
          </React.Fragment>
        )}
  
        <tr className="summary-table__section-row">
          <th colSpan={solutionCount + 1}>
            {'Tillval'}
          </th>
        </tr>

        <tr className="summary-table__extras-row">
          <td className="summary-table__title-cell">
            {'Valda tillval'}
          </td>
          {solutions.map(s => {
            const extras =
              selectedExtras && Array.isArray(selectedExtras[s.key])
                ? selectedExtras[s.key]
                : []
            return (
              <td key={s.key}>
                {extras.map(extraKey => {
                  const extra = getExtra(extraKey)
                  return (
                    <div key={extraKey} className="summary-table__extra">
                      {extra ? extra.title : extraKey}
                    </div>
                  )
                })}
              </td>
            )
          })}
        </tr>
      </tbody>
    </table>
  )
}

export default withRouter(props => <SummaryTable {...props} />)
