import React from 'react'
import cn from 'classnames'
import './WhiteButton.css'

const WhiteButton = ({ children, className, type = 'button', ...extraProps }) =>
  <button type={type} className={cn('white-button', className)} {...extraProps}>
    {children}
  </button>

export default WhiteButton
