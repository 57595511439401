import React from 'react'
import { withRouter } from 'react-router-dom'

class StartPage extends React.Component {
  componentDidMount ()  {
    this.props.history.push('/products')
  }

  render ()  {
    return null
  }
}

export default withRouter(props => <StartPage {...props} />)
