import React from 'react'
import { get } from '../../utils/get-prop'
import cn from 'classnames'

const values = [
  {
    key: 'low',
    value: 'Lågt'
  },
  {
    key: 'medium',
    value: 'Normalt'
  },
  {
    key: 'high',
    value: 'Högt'
  }
]

const ProductValueInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const currentValue = get(details, `${solution.key}.${questionKey}`)

  return (
    <div className="productsValue">
      {values.map(({ key, value }) =>
        <div
          key={key}
          className={cn('productValue', key, {
            'productValue--selected': currentValue === key
          })}
          onClick={() => {
            onDetailsChange(solution.key, questionKey, key)
          }}
        >
          {value}
        </div>
      )}
    </div>
  )
}

export default ProductValueInput
