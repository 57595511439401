import React from 'react'

import Underline from '../../components/Underline/Underline'
import MediumDivider from '../../components/MediumDivider/MediumDivider'
import SmallDivider from '../../components/SmallDivider/SmallDivider'
import HeadlineMega from '../../components/HeadlineMega/HeadlineMega'
import RedButton from '../../components/RedButton/RedButton'
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
import Appear from '../../components/Appear/Appear'
import dhltools from '../../utils/get-dhltools'

const SignIn = () =>
  <BackgroundImage>
    <Appear>
      <SmallDivider />
      <HeadlineMega>
        Välkommen till AppSell
      </HeadlineMega>

      <SmallDivider />
      <Underline />
      <MediumDivider />
      <div style={{ width: 424, margin: '0 auto' }}>
        <a href={dhltools.loginUrl}>
          <RedButton>Logga in via DHLTools.se</RedButton>
        </a>
      </div>
    </Appear>
  </BackgroundImage>

export default SignIn
