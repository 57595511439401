import React from 'react'
import { get } from '../../utils/get-prop'

import ToggleInput from '../ToggleInput/ToggleInput'

const ProductValueInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const currentValue = get(details, `${solution.key}.${questionKey}`)

  return (
    <div style={{ textAlign: 'center' }}>
      <ToggleInput
        trueLabel="Normalt"
        falseLabel="Begränsad yta/portar"
        value={currentValue}
        onClick={newValue => {
          onDetailsChange(solution.key, questionKey, newValue)
        }}
      />
    </div>)
}

export default ProductValueInput
