import React from 'react'
import { withRouter } from 'react-router-dom'
import './Navbar.css'
import Menu from '../Menu/Menu'
import dhltools from '../../utils/get-dhltools'

const Navbar = ({ history, user, onSignOut }) =>
  <header className="app-header">
    <div className="logo" onClick={() => history.push('/products')} />

    {user &&
      <Menu
        user={user}
        items={[
          {
            title: 'Mina uppgifter',
            href: dhltools.settingsUrl
          },
          {
            title: 'Kundinfo',
            onClick: () => {
              history.push('/new')
            }
          },
          { title: 'Logga ut', onClick: onSignOut }
        ]}
      />}
  </header>

export default withRouter(props => <Navbar {...props} />)
