import React from 'react'
import './ShowMoreButton.css'

const ShowMoreButton = ({ onClick, isShowingMore }) =>
  <div className="show-more-button" onClick={onClick}>
    <p className="show-more-button__text">
      {isShowingMore ? 'Visa bara rekommenderade tillval' : 'Visa alla tillval'}
    </p>
  </div>

export default ShowMoreButton
