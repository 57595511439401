import React from 'react'
import './GoBackButton.css'

const GoBackButton = ({ history, previousStep, isHidden }) =>
  <div
    style={(!previousStep || isHidden) ? { opacity: '0', pointerEvents: 'none' } : {}}
    className="goBackButton"
    onClick={e => {
      e.preventDefault()
      history.push(previousStep.path)
    }}
  >
    <p className="goBackButton-text">Gå tillbaka</p>
  </div>

export default GoBackButton
