import React from 'react'
import { get } from '../../utils/get-prop'
import OptionCards from '../OptionCards/OptionCards'
import OptionCard from '../OptionCard/OptionCard'
import './FreightTypesInput.css'
import values from '../../data/freight-types.json'

const FreightTypesInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const currentValue = get(details, `${solution.key}.${questionKey}`, [])

  return (
    <OptionCards>
      {values.map(({ key, value }) =>
        <OptionCard 
          key={key}
          onClick={() => {
            let newValue = currentValue
            if (newValue.includes(key)) {
              newValue.splice(newValue.indexOf(key), 1)
            } else {
              newValue.push(key)
            }

            onDetailsChange(solution.key, questionKey, newValue)
          }}
          isActive={currentValue && currentValue.includes(key)}
          icon={`freight-type--${key}`}
          text={value}
        />
      )}
    </OptionCards>
  )
}
export default FreightTypesInput
