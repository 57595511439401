import React from 'react'
import cn from 'classnames'
import './OptionCard.css'

const OptionCard = ({ onClick, icon, text, isActive, children, style }) =>
  <div className="option-card" onClick={onClick} style={style}>
    {children}
    <div
      className={cn('option-card__hover', icon, {
        'option-card__hover--active': isActive
      })}
    >
      <p className={'option-card__text'}>
        {text}
      </p>
    </div>

  </div>

export default OptionCard
