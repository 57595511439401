/* eslint react/jsx-no-target-blank: 0 */

import React from 'react'
import cn from 'classnames'

import './FooterSolutionItem.css'

import servicePointPdf from '../../assets/pdf/DHL Freight Service Point.pdf'
import parcelConnectPdf from '../../assets/pdf/DHL Freight Parcel connect.pdf'
import dhlPaketPdf from '../../assets/pdf/DHL Freight Paket.pdf'
import paketExportPdf from '../../assets/pdf/DHL Freight Paket Export.pdf'
import dhlPallPdf from '../../assets/pdf/DHL Freight Pall.pdf'
import dhlStyckePdf from '../../assets/pdf/DHL Freight Stycke.pdf'
import homeDeliveryPdf from '../../assets/pdf/DHL Freight Home Delivery.pdf'
import dhlPartiPdf from '../../assets/pdf/DHL Freight Parti.pdf'
import euroconnectPdf from '../../assets/pdf/DHL Freight Euroconnect.pdf'

const pdfs = {
  'service-point': servicePointPdf,
  'parcel-connect': parcelConnectPdf,
  'dhl-paket': dhlPaketPdf,
  'paket-export': paketExportPdf,
  'dhl-pall': dhlPallPdf,
  'dhl-stycke': dhlStyckePdf,
  'home-delivery': homeDeliveryPdf,
  'dhl-parti': dhlPartiPdf,
  'euroconnect': euroconnectPdf
}

const FooterSolutionItem = ({
  solution,
  isHighlighted,
  isMatchingFilter,
  isCurrentStep,
  hideInfoLink,
  onClick,
  onRemoveClick
}) => {
  return (
    <div className="footer-solution-item" key={solution.key}>
      <div
        className={cn('footer-solution-item__wrapper', {
          'footer-solution-item__wrapper--selected': isHighlighted,
          'footer-solution-item__wrapper--current-step': isCurrentStep
        })}
      >
        <div
          key={solution.key}
          className={cn('footer-solution-item__bg', {
            'footer-solution-item__bg--active': isMatchingFilter
          })}
          onClick={onClick}
        >
          <div className={cn('footer-solution-item__icon', solution.key)} />

          {onRemoveClick &&
            <div
              className="footer-solution-item__remove"
              onClick={onRemoveClick}
            >
              &times;
            </div>}
        </div>
      </div>
      <p className="footer-solution-item__title">
        {solution.name}

        {!hideInfoLink &&
          <a
            href={pdfs[solution.key]}
            target="_blank"
            className="footer-solution-item__info-link"
          >
            Show PDF
          </a>}
      </p>
    </div>
  )
}

export default FooterSolutionItem
