import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import cn from 'classnames'
import HeadlineSmall from '../../components/HeadlineSmall/HeadlineSmall'

import getShippingItem from '../../utils/getShippingItem'
import getMarket from '../../utils/getMarket'
import getRecipient from '../../utils/getRecipient'

import '../RecipientsPicker/RecipientsPicker.css'
import '../MarketPicker/MarketPicker.css'
import '../ShippingItemsPicker/ShippingItemsPicker.css'

import './SummaryCard.css'

const printTime = (from, to) => {
  const f = from ? from[0] : '07:00'
  const t = to ? to[0] : '17:00'

  return `${f} - ${t}`
}

const SummaryFilter = ({ icon, title }) =>
  <div className={cn('summary-filter', icon)}>
    <div className="summary-filter__title">
      {title}
    </div>
  </div>

const SummaryCard = ({ filters, details }) => {
  const {
    shippingItems = [],
    recipients = [],
    markets = [],
    openingHoursFrom, 
    openingHoursTo,
    pickupTimesFrom,
    pickupTimesTo
  } = filters
  console.log(filters)

  const generalInfo = details && details.general && details.general.freetextInfo

  return (
    <div className="summary-card">
      <div style={{ maxWidth: 500, margin: '0 auto 28px' }}>
        <Row gutter={160}>
          <Col span={6}>
            <HeadlineSmall>Öppettider</HeadlineSmall>
            <p className="regular-text text--center" style={{ lineHeight: 1.5, padding: 0, margin: 0 }}>
              {printTime(openingHoursFrom, openingHoursTo)}
            </p>
          </Col>
          <Col span={6}>
            <HeadlineSmall>Hämtningstider</HeadlineSmall>
            <p className="regular-text text--center" style={{ lineHeight: 1.5, padding: 0, margin: 0 }}>
              {printTime(pickupTimesFrom, pickupTimesTo)}
            </p>
          </Col>
        </Row>
      </div>

      <Row gutter={80}>
        <Col span={4}>
          <HeadlineSmall>Mottagare</HeadlineSmall>
          <div className="summary-filters">
            {recipients
              .map(getRecipient)
              .map(e =>
                <SummaryFilter
                  key={e.key}
                  icon={`recipients-icon-${e.key}`}
                  title={e.value}
                />
              )}
          </div>
        </Col>
        <Col span={4}>
          <HeadlineSmall>Marknader</HeadlineSmall>
          <div className="summary-filters">
            {markets
              .map(getMarket)
              .map(e =>
                <SummaryFilter
                  key={e.key}
                  icon={`market-icon-${e.key}`}
                  title={e.value}
                />
              )}
          </div>
        </Col>
        <Col span={4}>
          <HeadlineSmall>Godstyp</HeadlineSmall>
          <div className="summary-filters" style={{ minHeight: 50 }}>
            {shippingItems
              .map(getShippingItem)
              .map(e =>
                <SummaryFilter
                  key={e.key}
                  icon={`shipping-items-icon-${e.key}`}
                  title={e.value}
                />
              )}
          </div>
        </Col>
      </Row>

      <div>
        <HeadlineSmall>Övriga kommentarer</HeadlineSmall>
        <p className="regular-text text--center">
          {generalInfo}
        </p>
      </div>
    </div>
  )
}

export default SummaryCard
