import extras from '../data/extras.json'

function getExtra(key) {
  const res = extras.find(el => {
    return el.key === key
  })
  return res
}

export default getExtra
