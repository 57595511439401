import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-dragdata'
import { get } from '../../utils/get-prop'

var button = new Image(52, 52)

button.src =
  'data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" height="52px" width="52px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve"><style type="text/css">.st0{display:none;fill:%23FFFFFF;} .st1{fill:%23FFFFFF;stroke:%23E3E3E3;} .st2{fill:none;stroke:%23111111;stroke-width:2;}</style><rect x="-188" y="-729" class="st0" width="1440" height="2828"/><g><circle id="path-1" cx="30" cy="30" r="26"/><circle class="st1" cx="30" cy="30" r="25.5"/><polyline id="Path-2" class="st2" points="24,26 30,20 36,26"/><polyline id="Path-2_1_" class="st2" points="24,34 30,40 36,34"/></g></svg>'

const generalOptions = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 30,
      bottom: 0
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: false, drawBorder: false },
        scaleShowLabels: true,
        ticks: {
          fontColor: '#000000',
          fontSize: 16,
          padding: 20,
          fontStyle: 'bold'
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: false
        },
        ticks: {
          min: 0,
          max: 100,
          scaleSteps: 50,
          scaleStartValue: 1,
          maxTicksLimit: 3,
          padding: 40,
          fontColor: '#D8D8D8',
          fontSize: 15,
          callback: function(label, index, labels) {
            switch (label) {
              case 50:
                return 'Normal'
              default:
                return ''
            }
          }
        },
        gridLines: {
          drawBorder: false,
          display: false,
          color: '#E3E3E3',
          tickMarkLength: 0,
          borderDash: [6, 6],
          lineWidth: 2,
          offsetGridLines: false
        }
      }
    ]
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  dragData: true
}

const PeakSeasonInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const solutionKey = solution.key
  window.currentSolutionKey = solution.key // Kinda the ugliest solution ever. Will prevent having two of this component at the same time. 
  const dataKey = `${solutionKey}.${questionKey}`
  const valueFromProps = get(details, dataKey, [50, 50, 50, 50])
  window.valueFromPropsHax = valueFromProps
  const data = canvas => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 190) //Canvas height, not data value.

    gradient.addColorStop(0, 'rgba(194, 42, 35, 1)')
    gradient.addColorStop(0.6, 'rgba(254, 204, 0, 1)')
    gradient.addColorStop(1, 'rgba(255, 255, 255, 1)')

    const gradientLine = ctx.createLinearGradient(0, 0, 0, 250)
    gradientLine.addColorStop(0, 'rgba(194, 42, 35, 1)')
    gradientLine.addColorStop(1, 'rgba(255, 204, 0, 1)')

    return {
      labels: ['VÅR', 'SOMMAR', 'HÖST', 'VINTER'],

      datasets: [
        {
          data: valueFromProps,
          backgroundColor: gradient,
          borderColor: gradientLine,
          borderWidth: 5,
          pointStyle: button,
          pointRadius: 25,
          pointHoverRadius: 25,
          spanGaps: false
        }
      ]
    }
  }
  const options = {}

  options[solutionKey] = {
    ...generalOptions,
    onDragEnd:  (e, _, index, value) => {    
      const solutionKey = window.currentSolutionKey
      console.log('On drag end')
      console.log({ valueFromProps })
      console.log({ index, value })
      console.log({solutionKey})
      // let newValue = get(
      //   details,
      //   `${solutionKey}.${questionKey}`,
      //   [50, 50, 50, 50]
      // );
      let newValue = window.valueFromPropsHax
      newValue[index] = value > 70 || value < 30 ? value : 50
      console.log({ newValue })
      onDetailsChange(solutionKey, questionKey, newValue)
    }
  }

  return (
    <div style={{ height: 250 }}>
      <Line
        height={250}
        width={800}
        data={data}
        labels={['VÅR', 'SOMMAR', 'HÖST', 'VINTER']}
        options={options[solutionKey]}
      />
    </div>
  )
}

export default PeakSeasonInput
