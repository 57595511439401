import React from 'react'
import { get } from '../../utils/get-prop'

import Slider from '../Slider/Slider'

const PalletsStackableInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const value = get(details, `${solution.key}.${questionKey}`, 0)

  return (
    <Slider
      min={0}
      max={100}
      step={20}
      value={value}
      unit={'%'}
      onChange={val => {
        onDetailsChange(solution.key, questionKey, val)
      }}
    />
  )
}

export default PalletsStackableInput
