import React from 'react'
import './ProductTypeExtraInfoInput.css'

const ProductTypeExtraInfoInput = ({ extraInfo, currentValue, onChange }) => {
  const { value } = extraInfo
  return (
    <div
      className="extraInfoInputWrapper"
      onClick={e => e.stopPropagation()}
      onChange={() => {
        onChange(!currentValue)
      }}
    >
      <label className="checkbox-label">
        <span className="extraInfoInputText">
          {value}
        </span>

        <input type="checkbox" checked={currentValue || false} readOnly />

        <span className="checkbox-custom" />
      </label>
    </div>
  )
}

export default ProductTypeExtraInfoInput
