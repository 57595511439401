import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import TextInput from '../TextInput/TextInput'
import RedButton from '../RedButton/RedButton'
import WhiteButton from '../WhiteButton/WhiteButton'

import './CustomerDetailsCard.css'

class CustomerDetailsCard extends Component {
  onChange = e => {
    let { customer = {} } = this.props
    customer[e.target.name] = e.target.value

    this.props.onUpdateCustomerDetails(customer)
  };

  onSubmit = e => {
    e.preventDefault()
    this.props.history.push('/products')
  };

  render() {
    const { customer = {}, onResetForm } = this.props
    const { name, contactPerson, title, email, phone } = customer

    return (
      <form onSubmit={this.onSubmit}>
        <div className="customer-details-card">
          <TextInput
            name="name"
            value={name || ''}
            placeholder="Kund"
            required
            onChange={this.onChange}
          />
          <TextInput
            name="contactPerson"
            value={contactPerson || ''}
            placeholder="Kontaktperson"
            onChange={this.onChange}
          />
          <TextInput
            name="title"
            value={title || ''}
            placeholder="Titel"
            onChange={this.onChange}
          />
          <TextInput
            type="email"
            name="email"
            value={email || ''}
            placeholder="E-postadress"
            onChange={this.onChange}
          />
          <TextInput
            value={phone || ''}
            name="phone"
            placeholder="Telefonnummer"
            onChange={this.onChange}
          />
        </div>
        <RedButton>Spara och fortsätt</RedButton>
        <WhiteButton onClick={onResetForm}>Nollställ</WhiteButton>
      </form>
    )
  }
}

export default withRouter(props => <CustomerDetailsCard {...props} />)
