function solutionMatchesFilterCheck(filters = {}, solution = {}) {
  if (!solution.filters) {
    return false
  }

  const { markets, recipients, shippingItems } = solution.filters

  let recipientsMatch = false
  let marketsMatch = false
  let shippingMatch = false

  // Check if the solution handles the selected recipients
  if (Array.isArray(recipients) && Array.isArray(filters.recipients)) {
    const result = recipients.filter(function(n) {
      return filters.recipients.indexOf(n) > -1
    })

    if (result.length > 0) recipientsMatch = true
  }
  // If no recipients is selected, don't filter on it
  if (!filters.recipients || filters.recipients.length === 0) {
    recipientsMatch = true
  }

  // Check if the solution handles the selected markets
  if (Array.isArray(markets) && Array.isArray(filters.markets)) {
    const result = markets.filter(function(n) {
      return filters.markets.indexOf(n) > -1
    })
    if (result.length > 0) marketsMatch = true
  }
  // If no markets is selected, don't filter on it
  if (!filters.markets || filters.markets.length === 0) {
    marketsMatch = true
  }

  // Check if the solution handles the selected shipping items
  if (Array.isArray(shippingItems) && Array.isArray(filters.shippingItems)) {
    const result = shippingItems.filter(function(n) {
      return filters.shippingItems.indexOf(n) > -1
    })
    if (result.length > 0) shippingMatch = true
  }
  // If no shipping items is selected, don't filter on it
  if (!filters.shippingItems || filters.shippingItems.length === 0) {
    shippingMatch = true
  }

  return recipientsMatch && marketsMatch && shippingMatch
}

export default solutionMatchesFilterCheck