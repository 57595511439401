import React from 'react'
import TimeRangeInput from '../TimeRangeInput/TimeRangeInput'

const OpeningHours = ({ filters, onAddFilter }) => {
  const { openingHoursFrom, openingHoursTo } = filters 
  const from = openingHoursFrom ? openingHoursFrom[0] : '07:00'
  const to = openingHoursTo ? openingHoursTo[0] : '17:00'
  
  return (
    <div className="inputBar">
      <TimeRangeInput 
        fromValue={from}
        toValue={to}
        onFromChange={val => {
          onAddFilter('openingHoursFrom', val, true)
        }}
        onToChange={val => {
          onAddFilter('openingHoursTo', val, true)
        }}
      />
    </div>
  )
}

export default OpeningHours
