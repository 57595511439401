import getSolution from './getSolution'

export function getSteps(selectedSolutions, pathname) {
  let n = 3

  const solutions = Array.isArray(selectedSolutions)
    ? selectedSolutions.map(key => {
        const solution = getSolution(key)
        return {
          n: n++,
          key: `details/${key}`,
          title: solution.name,
          path: `/details/${key}`,
          active: pathname === `/details/${key}`
        }
      })
    : []

  return [
    {
      n: 1,
      key: 'start',
      title: 'Start',
      path: '/new',
      active: false
    },
    {
      n: 2,
      key: 'products',
      title: 'Transportbehov',
      path: '/products',
      active: pathname === '/'
    },
    ...solutions,
    {
      n: n++,
      key: 'summary',
      title: 'Sammanfattning',
      path: '/summary',
      active: pathname === '/summary'
    }
  ]
}

export function getCurrentStep(selectedSolutions, pathname) {
  const steps = getSteps(selectedSolutions, pathname)
  if (pathname === '/products') return steps[1]

  return steps.find(e => {
    return e.path === pathname
  })
}

export function getNextStep(selectedSolutions, pathname) {
  if (pathname === '/summary') return false

  const steps = getSteps(selectedSolutions, pathname)

  const index = steps.findIndex(e => {
    return e.path === pathname
  })

  return steps[index + 1]
}

export function getPreviousStep(selectedSolutions, pathname) {
  const steps = getSteps(selectedSolutions)

  const index = steps.findIndex(e => {
    return e.path === pathname
  })

  return steps[index - 1]
}

export function getFirstDetailStep (selectedSolutions) {
  return getSteps(selectedSolutions).find(s => s.key.startsWith('details'))
}