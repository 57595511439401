import React from 'react'
import MediumDivider from '../../components/MediumDivider/MediumDivider'
import SmallDivider from '../../components/SmallDivider/SmallDivider'
import { Row, Col } from 'react-simple-flex-grid'

import Underline from '../../components/Underline/Underline'

import OpeningHours from '../../components/OpeningHours/OpeningHours'
import PickupTimes from '../../components/PickupTimes/PickupTimes'
import HeadlineLarge from '../../components/HeadlineLarge/HeadlineLarge'
import HeadlineSmall from '../../components/HeadlineSmall/HeadlineSmall'

import RecipientsPicker from '../../components/RecipientsPicker/RecipientsPicker'
import MarketPicker from '../../components/MarketPicker/MarketPicker'
import ShippingItemsPicker from '../../components/ShippingItemsPicker/ShippingItemsPicker'
import Appear from '../../components/Appear/Appear'

import './Products.css'

const Products = ({ customerName, filters, onAddFilter }) =>
  <Appear>
    <MediumDivider />
    <HeadlineLarge>TRANSPORTBEHOV</HeadlineLarge>
    <Underline />
    <SmallDivider />
    <div className="container">
      <Row gutter={80}>
        <Col span={6}>
          <HeadlineSmall>Vilka öppettider har {customerName}?</HeadlineSmall>
          <OpeningHours filters={filters} onAddFilter={onAddFilter} />
        </Col>
        <Col span={6}>
          <HeadlineSmall>När kan vi hämta?</HeadlineSmall>
          <PickupTimes filters={filters} onAddFilter={onAddFilter} />
        </Col>
      </Row>
    </div>

    <SmallDivider />
    <hr />
    <SmallDivider />

    <div className="container">
      <Row gutter={80}>
        <Col span={4}>
          <HeadlineSmall>Mottagare</HeadlineSmall>      
          <RecipientsPicker filters={filters} onAddFilter={onAddFilter} />
        </Col>
        <Col span={4}>
          <HeadlineSmall>Marknader</HeadlineSmall>      
          <MarketPicker filters={filters} onAddFilter={onAddFilter} />
        </Col>
        <Col span={4}>
          <HeadlineSmall>Godstyp</HeadlineSmall>      
          <ShippingItemsPicker filters={filters} onAddFilter={onAddFilter} />
        </Col>
      </Row>
    </div>
  </Appear>

export default Products
