import React from 'react'
import { get } from '../../utils/get-prop'
import ThumbSlider from '../ThumbSlider/ThumbSlider'

const PercentagePalletsInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const value = get(details, `${solution.key}.${questionKey}`, 50)
  const min = 0
  const max = 100
  const step = 1
  const valueHelpall = value
  const valueHalvpall = 100 - valueHelpall
  const unit = '%'

  return (
    <div className="percentagePalletsWrapper">
      <h3 className="percentagePalletsHeader">Helpall</h3>
      <div
        className="thumb-slider-wholePallet"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <span className="thumb-slider-label">
          {min}
        </span>
        <ThumbSlider
          min={min}
          max={max}
          unit={unit}
          step={step}
          value={valueHelpall}
          onChange={val => {
            onDetailsChange(solution.key, questionKey, val)
          }}
        />
        <span className="thumb-slider-label">
          {max}
        </span>
      </div>

      <h3 className="percentagePalletsHeader" style={{ textAlign: 'center' }}>Halvpall</h3>
      <div
        className="thumb-slider-halfPallet"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <span className="thumb-slider-label">
          {min}
        </span>
        <ThumbSlider
          min={min}
          max={max}
          step={step}
          value={valueHalvpall}
          unit={unit}
          onChange={val => {
            onDetailsChange(solution.key, questionKey, 100 - val)
          }}
        />
        <span className="thumb-slider-label">
          {max}
        </span>
      </div>
    </div>
  )
}

export default PercentagePalletsInput
