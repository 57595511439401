/* eslint-disable array-callback-return */

import solutions from '../data/solutions.json'

const getAllQuestionSections = () => {
  const allQuestionSectionsObject = {}

  solutions.map(solution => {
    solution.questionSections.map(qs => {
      if (!allQuestionSectionsObject[qs.key]) {
        allQuestionSectionsObject[qs.key] = {
          key: qs.key,
          title: qs.title,
          questions: {}
        }
      }

      qs.questions.map(q => {
        if (!allQuestionSectionsObject[qs.key].questions[q.key]) {
          allQuestionSectionsObject[qs.key].questions[q.key] = {
            key: q.key,
            title: q.shortTitle ? q.shortTitle : q.title
          }
        }
      })
    })
  })

  const allQuestionSections = Object.values(allQuestionSectionsObject)

  return allQuestionSections.map(qs => {
    return {
      ...qs,
      questions: Object.values(qs.questions)
    }
  })
}

export default getAllQuestionSections
