import React from 'react'
import cn from 'classnames'
import './Step.css'

const Step = ({ firstDetailStep, pathname, history }) => {
  return (
    <React.Fragment>
      <a
        className="step"
        href="/new"
        onClick={e => {
          e.preventDefault()
          history.push('/new')
        }}
      >
        <div
          className={cn('step-circle', {
            'step-circle--active': pathname === '/new'
          })}
        >
          <p className="step-number">1</p>
        </div>
        <p className="step-text">Start</p>
      </a>

      <a
        href="/products"
        className="step"
        onClick={e => {
          e.preventDefault()
          history.push('/products')
        }}
      >
        <div
          className={cn('step-circle', {
            'step-circle--active': pathname.startsWith('/products')
          })}
        >
          <p className="step-number">2</p>
        </div>
        <p className="step-text">Transportbehov</p>
      </a>

<a
        href="/details"
        className="step"
        onClick={e => {
          e.preventDefault()
          
          if (firstDetailStep) {
            history.push(firstDetailStep.path)
          }
        }}
      >
        <div
          className={cn('step-circle', {
            'step-circle--active': pathname.startsWith('/details')
          })}
        >
          <p className="step-number">3</p>
        </div>
        <p className="step-text">Produktval</p>
      </a>
      
      <a
        href="/summary"
        className="step"
        onClick={e => {
          e.preventDefault()
          history.push('/summary')
        }}
      >
        <div
          className={cn('step-circle', {
            'step-circle--active': pathname === '/summary'
          })}
        >
          <p className="step-number">4</p>
        </div>
        <p className="step-text">Sammanfattning</p>
      </a>
    </React.Fragment>
  )
}

export default Step
