import React from 'react'
import TimeRangeInput from '../TimeRangeInput/TimeRangeInput'

const PickupTimes = ({ filters, onAddFilter }) => {
  const { pickupTimesFrom, pickupTimesTo } = filters 
  const from = pickupTimesFrom ? pickupTimesFrom[0] : '07:00'
  const to = pickupTimesTo ? pickupTimesTo[0] : '17:00'

  return (
    <div>
      <TimeRangeInput 
        fromValue={from}
        toValue={to}
        onFromChange={val => {
          onAddFilter('pickupTimesFrom', val, true)
        }}
        onToChange={val => {
          onAddFilter('pickupTimesTo', val, true)
        }}
      />
    </div>
  )
}

export default PickupTimes
