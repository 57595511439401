import React from 'react'
import cn from 'classnames'
import SmallDivider from '../SmallDivider/SmallDivider'
import MediumDivider from '../MediumDivider/MediumDivider'
import PercentagePalletsInput from '../PercentagePalletsInput/PercentagePalletsInput'
import NumberOfPackagesInput from '../NumberOfPackagesInput/NumberOfPackagesInput'
import NumberOfShipmentsInput from '../NumberOfShipmentsInput/NumberOfShipmentsInput'
import AverageWeightInput from '../AverageWeightInput/AverageWeightInput'
import PeakSeasonInput from '../PeakSeasonInput/PeakSeasonInput'
import ProductTypesInput from '../ProductTypesInput/ProductTypesInput'
import FreightTypesInput from '../FreightTypesInput/FreightTypesInput'
import ProductValueInput from '../ProductValueInput/ProductValueInput'
import ReturnPercentageInput from '../ReturnPercentageInput/ReturnPercentageInput'
import UnloadingProcedureInput from '../UnloadingProcedureInput/UnloadingProcedureInput'
import PreparedForShipmentInput from '../PreparedForShipmentInput/PreparedForShipmentInput'
import FollowUpTextInput from '../FollowUpTextInput/FollowUpTextInput'
import PalletsStackableInput from '../PalletsStackableInput/PalletsStackableInput'
import ExtraCapacityInput from '../ExtraCapacityInput/ExtraCapacityInput'
import LevtidOnlineInput from '../LevtidOnlineInput/LevtidOnlineInput'
import NumberInput from '../NumberInput/NumberInput'
import EuroconnectShipmentsInput from '../EuroconnectShipmentsInput/EuroconnectShipmentsInput'

import HeadlineSmall from '../HeadlineSmall/HeadlineSmall'
import HeadlineMedium from '../HeadlineMedium/HeadlineMedium'
import Appear from '../Appear/Appear'
import './FollowUpQuestions.css'

const FollowUpQuestions = ({
  solution,
  customerName,
  onDetailsChange,
  details
}) =>
  <div>
    {solution.questionSections.length === 0 &&
      <p
      >{`No follow up questions added for solution ${solution.name}, add then to solutions.json`}</p>}
    {solution.questionSections.map(section =>
      <section
        className={cn('details-section', `details-section--${section.key}`)}
        key={section.key}
      >
        <MediumDivider />

        {section.title !== 'Volym' &&
          <Appear>
            <HeadlineMedium className="details-section__title">
              {section.title}
            </HeadlineMedium>
          </Appear>}

        {section.questions.map(question =>
          <Appear key={question.key}>
            <div
              className={cn(
                'details-question',
                `details-question--${question.key}`
              )}
            >
              <HeadlineSmall>
                {question.title
                  ? question.title.replace('$customerName', customerName)
                  : question.key}
              </HeadlineSmall>
              <SmallDivider />

              {/* SMELL: Do this in a nicer more dynamic way, couldn't figure out how though */}
              {question.component === 'NumberOfPackagesInput' &&
                <NumberOfPackagesInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'AverageWeightInput' &&
                <AverageWeightInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'PeakSeasonInput' &&
                <PeakSeasonInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'ProductTypesInput' &&
                <ProductTypesInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'FreightTypesInput' &&
                <FreightTypesInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'ProductValueInput' &&
                <ProductValueInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'ReturnPercentageInput' &&
                <ReturnPercentageInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'PercentagePalletsInput' &&
                <PercentagePalletsInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'NumberOfShipmentsInput' &&
                <NumberOfShipmentsInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'UnloadingProcedureInput' &&
                <UnloadingProcedureInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'PreparedForShipmentInput' &&
                <PreparedForShipmentInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'FollowUpTextInput' &&
                <FollowUpTextInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'PalletsStackableInput' &&
                <PalletsStackableInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'ExtraCapacityInput' &&
                <ExtraCapacityInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'LevtidOnlineInput' &&
                <LevtidOnlineInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'NumberInput' &&
                <NumberInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              {question.component === 'EuroconnectShipmentsInput' &&
                <EuroconnectShipmentsInput
                  onDetailsChange={onDetailsChange}
                  solution={solution}
                  questionKey={question.key}
                  details={details}
                  defaultValue={question.defaultValue}
                  {...question.props || {}}
                />}
              <MediumDivider />
            </div>
          </Appear>
        )}
      </section>
    )}
  </div>

export default FollowUpQuestions
