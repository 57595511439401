import React from 'react'
import markets from '../../data/markets.json'
import isEnabledFilter from '../../utils/isEnabledFilter'
import OptionCards from '../OptionCards/OptionCards'
import OptionCard from '../OptionCard/OptionCard'
import './MarketPicker.css'
const filterName = 'markets'

const MarketPicker = ({ filters, onAddFilter }) =>
  <OptionCards>
    {markets.map(({ key, value }) =>
      <OptionCard
        key={key}
        icon={`market-icon-${key}`}
        onClick={() => {
          onAddFilter(filterName, key)
        }}
        isActive={isEnabledFilter(filters, filterName, key)}
        text={value}
      />
    )}
  </OptionCards>

export default MarketPicker
