import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import dhltools from './get-dhltools'

export function withUser(WrappedComponent) {
  return props => {
    const [user, setUser] = useState(null)
    const getUser = () => {
      const token = window.localStorage.getItem('token')
      const decodedToken = jwtDecode(token)
      console.log(decodedToken)
      const { userId, apps } = decodedToken

      console.log('app access', apps)

      console.log('fetching user from')
      const userUrl = dhltools.userUrl(userId)
      console.log({ userUrl })
      return fetch(userUrl)
        .then(res => res.json())
        .then(res => {
          setUser(res)
        })
        .catch(err => {
          console.log('ERROR')
          console.log(err)
        })
    }
  
    useEffect(() => {
      getUser()
    }, [])

    return <WrappedComponent user={user} {...props} />
  }
}
