import React, { useState } from 'react'
import './Menu.css'

import userProfileFallback from '../../assets/profile-fallback.png'
import cogIcon from './settings.svg'

const MenuItem = ({ title, onClick, href }) =>{
  if (href) {
    return <a href={href} className="menu__item">{title}</a>
  }
  return <button onClick={onClick} type="button" className="menu__item">
    {title}
  </button>
}

const MenuItems = ({ items, user }) =>
  <nav className="menu">
    <div className="menu__user" style={{ paddingBottom: 20 }}>
      <img
        alt="User profile"
        src={user.image || userProfileFallback}
        style={{
          width: 40,
          height: 40,
          display: 'block',
          borderRadius: '50%',
          margin: '0 auto 10px'
        }}
      />
      <div style={{ textAlign: 'center', fontFamily: 'deliveryregular' }}>
        {user.name}
      </div>
    </div>

    {items.map((item, n) =>
      <MenuItem key={n} {...item}  />
    )}
  </nav>


const Menu = ({ items, user }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      <button type="button" onClick={() => { setExpanded(!expanded) }} className="menu-button">
        <img src={cogIcon} alt="Settings menu" />
      </button>

      {expanded && <MenuItems user={user} items={items} />}
    </div>
  )
}

export default Menu
