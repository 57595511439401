import React from 'react'
import Underline from '../../components/Underline/Underline'
import MediumDivider from '../../components/MediumDivider/MediumDivider'
import ProductExtras from '../../components/ProductExtras/ProductExtras'
import FollowUpQuestions from '../../components/FollowUpQuestions/FollowUpQuestions'

import 'react-rangeslider/lib/index.css'
import './Details.css'
import HeadlineLarge from '../../components/HeadlineLarge/HeadlineLarge'
import HeadlineSmall from '../../components/HeadlineSmall/HeadlineSmall'
import Appear from '../../components/Appear/Appear'
import FreetextInfoInput from '../../components/FreetextInfoInput/FreetextInfoInput'

import getSolution from '../../utils/getSolution'

const Details = ({
  details,
  match,
  customerName,
  onDetailsChange,
  onAddExtras,
  selectedExtras
}) => {
  const { params } = match
  const { key } = params

  const solution = getSolution(key)

  const hasRecommendedExtras = solution.suggestedExtras.length
  const hasMoreExtras = solution.extras.length
  
  const hasAnyExtras = hasRecommendedExtras || hasMoreExtras

  return (
    <React.Fragment>
      <section className="detailsWrapper">
        <MediumDivider />
        <Appear>
          <HeadlineLarge>
            {solution.name}
          </HeadlineLarge>
          <Underline />
        </Appear>

        <FollowUpQuestions
          details={details}
          solution={solution}
          customerName={customerName}
          onDetailsChange={onDetailsChange}
        />
      </section>

      {hasAnyExtras ?
        <React.Fragment>
          <hr />
          <section className="detailsWrapper">
            <MediumDivider />
            <HeadlineSmall>
              Rekommenderande tillval för {solution.name}
            </HeadlineSmall>
            <ProductExtras
              solution={solution}
              onAddExtras={onAddExtras}
              selectedExtras={selectedExtras}
            />
          </section>
        </React.Fragment> : null}
      <hr />
      <section className="detailsWrapper">
        <MediumDivider />
        <HeadlineSmall>
          Övriga kommentarer
        </HeadlineSmall>
        <FreetextInfoInput 
          details={details}
          onDetailsChange={onDetailsChange}
        />
      </section>
    </React.Fragment>
  )
}

export default Details
