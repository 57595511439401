const baseUrl = 'https://dhltools.se'

const isLocal = () => (window.location.hostname === 'localhost')

const baseWebUrl = isLocal() ? 'http://localhost:3000' : baseUrl
const baseApiUrl = isLocal() ? 'http://localhost:8080' : baseUrl
const returnUrl = isLocal() ? 'http://localhost:3001/new' : 'http://sellapp.dhltools.se/new'

export default {
  url: `${baseWebUrl}`,
  loginUrl: `${baseWebUrl}/get-token?returnUrl=${returnUrl}`,
  userUrl: id => `${baseApiUrl}/api/user/${id}`,
  settingsUrl: `${baseWebUrl}/settings`,
}
