import React from 'react'
import Slider from '@material-ui/lab/Slider'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import './ThumbSlider.css'

const theme = createMuiTheme({
  overrides: {
    MuiSlider: {
      // override root styles for the button component. https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Slider/Slider.js
      track: {
        backgroundColor: '#C22A23',
        borderRadius: 2.5,
        height: 5,
        marginTop:9.5
    
      },

      trackAfter: {
        opacity: 0,
      },

      thumb: {
        backgroundColor: '#FFFFFF',
        marginTop: 9.5,
        overflow: 'visible',
        width: 21,
        height: 21,
        boxShadow: '2px 3px 5px 2px rgba(171, 171, 171, 0.32)',
        '&$focused, &:hover': {
          boxShadow: '0px 2px 4px 0px rgba(171, 171, 171, 0.32)'
          //width: 25,
          //height: 25,
        },
        '&$activated': {
          boxShadow: '2px 2px 4px 2px rgba(171, 171, 171, 0.32)'
        },
        '&$jumped': {
          boxShadow: '1px 2px 4px 1px rgba(171, 171, 171, 0.32)'
        }
      }
    }
  }
})

const ThumbSlider = ({ min, max, step, value, onChange, unit }) => {
  return (
    <div className="thumb-slider">
      <MuiThemeProvider theme={theme}>
        <Slider
          min={min}
          max={max}
          step={step}
          thumb={
            <div className="thumbWithValue">
              <div
                onClick={() => {
                  onChange(value - 1)
                }}
              >
                <div className="sliderMinusArrow" />
              </div>
              {value}
              <span className="thumb-slider-label-percentage">
                {unit}
              </span>
              <div
                onClick={() => {
                  onChange(value + 1)
                }}
              >
                <div className="sliderPlusArrow" />
              </div>
            </div>
          }
          value={value}
          onChange={(e, val) => {
            onChange(val)
          }}
        />
      </MuiThemeProvider>
    </div>
  )
}

export default ThumbSlider
