import React from 'react'
import { get } from '../../utils/get-prop'
import './FreetextInfoInput.css'
const FreetextInfoInput = ({ details, onDetailsChange }) => {
  const currentValue = get(details, 'general.freetextInfo', '')

  return (
    <textarea className="freetext-info-input" value={currentValue} onChange={e => {
      onDetailsChange('general', 'freetextInfo', e.target.value)
    }}>

    </textarea>
  )
}

export default FreetextInfoInput
