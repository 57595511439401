import React from 'react'
import SummaryCard from '../../components/SummaryCard/SummaryCard'
import SummaryTable from '../../components/SummaryTable/SummaryTable'
import MediumDivider from '../../components/MediumDivider/MediumDivider'
import SmallDivider from '../../components/SmallDivider/SmallDivider'
import HeadlineLarge from '../../components/HeadlineLarge/HeadlineLarge'
import Underline from '../../components/Underline/Underline'
import Appear from '../../components/Appear/Appear'
import EcSummarys from '../../components/EcSummarys/EcSummarys'
import PrintableSummary from '../../components/PrintableSummary/PrintableSummary'

const Summary = ({
  details,
  customerName,
  filters,
  selectedSolutions,
  selectedExtras,
  user,
  customer
}) =>
  <div>
    <Appear>
      {Array.isArray(selectedSolutions) &&
        selectedSolutions.length > 0 &&
          <div className="summary-view">
            <MediumDivider />
            <HeadlineLarge>Sammanfattning</HeadlineLarge>
            <SmallDivider />
            <Underline />
            <MediumDivider />
            <p style={{ textAlign: 'center', maxWidth: 600, margin: '0px auto 50px', fontSize: 20, fontFamily: 'deliveryregular' }}>Nedan sammanfattas de produkter som passar era transportbehov bäst, anpassade efter era önskemål och med valda tilläggstjänster.</p>
            <SummaryCard details={details} filters={filters} />
            <SmallDivider />
            <hr />
            <SmallDivider />
            <SummaryTable
              selectedSolutions={selectedSolutions}
              customerName={customerName}
              details={details}
              selectedExtras={selectedExtras}
            />
            <MediumDivider />

            <EcSummarys selectedSolutions={selectedSolutions} details={details} />
          </div>  
        }
    </Appear>
    <PrintableSummary
      selectedSolutions={selectedSolutions}
      customer={customer}
      user={user}
      filters={filters}
      details={details}
      selectedExtras={selectedExtras}
    />
    {!(Array.isArray(selectedSolutions) && selectedSolutions.length > 0) &&
      <div>No products selected</div>}
  </div>

export default Summary
