import React from 'react'
import './SubmitButton.css' 

const SubmitButton = () => (
    <div className="submitButton" onClick={e => {
        e.preventDefault()
        window.print()
    }}>
       <p className="submitButton-text">Spara som PDF</p>
    </div>
)

export default SubmitButton