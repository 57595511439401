import React from 'react'
import { withRouter } from 'react-router-dom'
import solutions from '../../data/solutions.json'
import FooterSolutionItem from '../FooterSolutionItem/FooterSolutionItem'
import solutionMatchesFilterCheck from '../../utils/solutionMatchesFilterCheck'
import './FooterSolutions.css'

const FooterSolutions = ({
  detailsStep,
  onAddSolution,
  filters,
  selectedSolutions = [],
  history
}) => {
  return (
    <div className="footer-solutions-wrapper">
      <section className="footer-solutions">
        {!detailsStep &&
          solutions.map(solution =>
            <FooterSolutionItem
              key={solution.key}
              isMatchingFilter={solutionMatchesFilterCheck(filters, solution)}
              isHighlighted={selectedSolutions.includes(solution.key)}
              solution={solution}
              onClick={() => {
                onAddSolution(solution.key)
              }}
            />
          )}

        {detailsStep &&
          solutions
            .filter(s => selectedSolutions.includes(s.key))
            .map(solution =>
              <FooterSolutionItem
                key={solution.key}
                solution={solution}
                isMatchingFilter={true}
                isHighlighted={true}
                isCurrentStep={detailsStep === solution.key}
                onRemoveClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  history.push('/products')
                  onAddSolution(solution.key)
                }}
                onClick={e => {
                  e.preventDefault()
                  history.push(`/details/${solution.key}`)
                }}
              />
            )}
      </section>
    </div>
  )
}

export default withRouter(props => <FooterSolutions {...props} />)
