import React from 'react'
import Select from 'react-dropdown-select'
import './TimeRangeInput.css'

const options = [
  { key: '00:00', value: '00:00', label: '00:00' },
  { key: '01:00', value: '01:00', label: '01:00' },
  { key: '02:00', value: '02:00', label: '02:00' },
  { key: '03:00', value: '03:00', label: '03:00' },
  { key: '04:00', value: '04:00', label: '04:00' },
  { key: '05:00', value: '05:00', label: '05:00' },
  { key: '06:00', value: '06:00', label: '06:00' },
  { key: '07:00', value: '07:00', label: '07:00' },
  { key: '08:00', value: '08:00', label: '08:00' },
  { key: '09:00', value: '09:00', label: '09:00' },
  { key: '10:00', value: '10:00', label: '10:00' },
  { key: '11:00', value: '11:00', label: '11:00' },
  { key: '12:00', value: '12:00', label: '12:00' },
  { key: '13:00', value: '13:00', label: '13:00' },
  { key: '14:00', value: '14:00', label: '14:00' },
  { key: '15:00', value: '15:00', label: '15:00' },
  { key: '16:00', value: '16:00', label: '16:00' },
  { key: '17:00', value: '17:00', label: '17:00' },
  { key: '18:00', value: '18:00', label: '18:00' },
  { key: '19:00', value: '19:00', label: '19:00' },
  { key: '20:00', value: '20:00', label: '20:00' },
  { key: '21:00', value: '21:00', label: '21:00' },
  { key: '22:00', value: '22:00', label: '22:00' },
  { key: '23:00', value: '23:00', label: '23:00' }
]

const style = {
  height: 75
}

const TimeRangeInput = ({ onFromChange = () => {}, onToChange = () => {}, fromValue, toValue }) => {
  return (
    <div className="time-range-input">
      <div className="time-range-input__input-wrapper">
        <Select
          options={options}
          separator
          values={[{
            value: fromValue,
            label: fromValue,
            key: fromValue
          }]}
          style={style}
          searchable={false}
          color={'#ffcc00'}
          onChange={(values) => {
            if (values && values[0] && values[0].value) {
              onFromChange(values[0].value)
            }
          }}
        />
      </div>
      <div className="time-range-input__divider">
        Till
      </div>
      <div className="time-range-input__input-wrapper">
        <Select
          options={options}
          separator
          values={[{
            value: fromValue,
            label: fromValue,
            key: fromValue
          }]}
          style={style}
          searchable={false}
          color={'#ffcc00'}
          onChange={(values) => {
            if (values && values[0] && values[0].value) {
              onToChange(values[0].value)
            }
          }}
        />
      </div>
    </div>
    )
}

export default TimeRangeInput