import React from 'react'
import './EcSummarys.css'
import EuroconnectSummary from '../../components/EuroconnectSummary/EuroconnectSummary'
import HeadlineSmall from '../../components/HeadlineSmall/HeadlineSmall'
import getSolution from '../../utils/getSolution'

const EcSummarys = ({ selectedSolutions, details }) => {
  const solutions = selectedSolutions.map(solutionKey => {
    const solution = getSolution(solutionKey)
    return solution
  })

  return (
    <div className="ec-summarys">
      {solutions.map(s => {
        console.log(s)
        if (s.key === 'parcel-connect' || s.key === 'paket-export' || s.key === 'euroconnect') {
          return (
            <div key={s.key}>
              <HeadlineSmall>{s.name}</HeadlineSmall>
              <EuroconnectSummary solution={s} details={details} />
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default EcSummarys