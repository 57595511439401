import React from 'react'
import recipients from '../../data/recipients.json'
import isEnabledFilter from '../../utils/isEnabledFilter'
import './RecipientsPicker.css'
import OptionCards from '../OptionCards/OptionCards'
import OptionCard from '../OptionCard/OptionCard'
const filterName = 'recipients'

const RecipientsPicker = ({ filters, onAddFilter }) =>
  <OptionCards>
    {recipients.map(({ key, value }) =>
      <OptionCard 
        key={key} 
        icon={`recipients-icon-${key}`}
        onClick={() => {
          onAddFilter(filterName, key)
        }}
        isActive={isEnabledFilter(
          filters,
          filterName,
          key
        )}
        text={value}
      />
    )}
  </OptionCards>

export default RecipientsPicker
