import React, { Component } from 'react'
import './EuroconnectShipmentsInput.css'
import OptionCards from '../OptionCards/OptionCards'
import OptionCard from '../OptionCard/OptionCard'
import cn from 'classnames'
import { get } from '../../utils/get-prop'

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const regions = [
  { 
    key: 'norden',
    title: 'Norden',
  },
  { 
    key: 'västeuropa',
    title: 'Västeuropa',
  },
  { 
    key: 'centraleuropa',
    title: 'Centraleuropa',
  },
  { 
    key: 'baltikum',
    title: 'Baltikum',
  },
  { 
    key: 'cis',
    title: 'CIS',
  },
]

const Region = ({ title, isActive, onClick, regionKey }) => {
  return (
    <OptionCard icon={`ec-map--${regionKey}`} style={{ width: 170 }} text={title} isActive={isActive} onClick={onClick}>
      {isActive && (
        <div className="ec-region-active-marker">
          <div className="ec-region-active-marker__gradient" />
        </div>
      )}
    </OptionCard>
  )
}

const CountryColumn = ({ children, hideImport }) => (
  <div className="ec-country-details__column">
    <div className="ec-country-details__column-header">
      {!hideImport && <span>Import</span>}
      <span>Export</span>
    </div>
    {children}
  </div>
)

const CountryDetails = ({ details, countryKey, onDetailsChange, questionKey, solutionKey, hideImport }) => {
  const importValue = get(details, `${solutionKey}.${questionKey}-${countryKey}-import`, '')
  const exportValue = get(details, `${solutionKey}.${questionKey}-${countryKey}-export`, '')
  
  return (
    <div className={cn('ec-country-details', `ec-country-details--${countryKey}`)} key={countryKey}>
      <div className="ec-country-details__name">
        {capitalize(countryKey)}
      </div>
      <div className={cn('ec-country-details__fields', {
        'ec-country-details__fields--hide-import': hideImport
      })}>
        {!hideImport && (
          <input 
          value={importValue}
          onChange={e => { onDetailsChange(solutionKey, `${questionKey}-${countryKey}-import`, e.target.value )}}
          type="number" 
          placeholder="0"
          className={cn('ec-country-details__field ec-country-details__field--import', {
            'ec-country-details__field--has-value': importValue > 0
          })} />
        )}
        <input 
          value={exportValue}
          onChange={e => { onDetailsChange(solutionKey, `${questionKey}-${countryKey}-export`, e.target.value )}}
          type="number" 
          placeholder="0"
          className={cn('ec-country-details__field ec-country-details__field--export', {
            'ec-country-details__field--has-value': exportValue > 0
          })} />
      </div>
    </div>
  )
}

const RegionDetails = ({ details, hideImport, countries, onClose, onDetailsChange, questionKey, solutionKey }) => {
  let column1, column2

  const countryKeys = Object.keys(countries)

  if (countryKeys.length > 7) {
    const halfLength = Math.ceil(countryKeys.length / 2)
    column1 = countryKeys.slice(0,halfLength)
    column2 = countryKeys.slice(halfLength)    
  } else {
    column1 = countryKeys
  }

  return (
    <div className="ec-region-details">
      <div className="ec-region-details__content">

        <button type="button" className="ec-region-details__close" onClick={onClose}>&times;</button>

        <CountryColumn hideImport={hideImport}>
          {column1.map(countryKey => 
            <CountryDetails 
              key={countryKey} 
              details={details}
              solutionKey={solutionKey}
              questionKey={questionKey}
              onDetailsChange={onDetailsChange} 
              countryKey={countryKey}
              hideImport={hideImport}
            />)}
        </CountryColumn>

        {column2 && (
          <CountryColumn hideImport={hideImport}>
            {column2.map(countryKey => 
              <CountryDetails 
                key={countryKey} 
                details={details}
                solutionKey={solutionKey}
                questionKey={questionKey}
                onDetailsChange={onDetailsChange} 
                countryKey={countryKey}
                hideImport={hideImport}
              />)}
          </CountryColumn>    
        )}
      </div>
    </div>
  )
}

class EuroconnectShipmentsInput extends Component {
  state = {
    openRegion: null
  }
  
  openRegion = openRegion => {
    this.setState({ openRegion })
  }

  closeRegion = () => { 
    this.setState({ openRegion: null })
  }

  render () {
    const { openRegion } = this.state
    const { defaultValue: regionData, details, solution, questionKey, onDetailsChange, hideImport = false } = this.props
    const solutionKey = solution.key
    console.log(regionData)

    const _regions = regions.filter(region => {
      console.log('check', region)
      return Boolean(regionData[region.key])
    })


    return (
      <section className="ec" style={{ width: 900, marginLeft: -50 }}>
        <OptionCards style={{ justifyContent: 'center' }}>
          {_regions.map(region => 
            <Region 
              title={region.title} 
              regionKey={region.key}
              key={region.key} 
              onClick={() => {
                this.openRegion(region.key)
              }}
              isActive={openRegion === region.key} />
          )}
        </OptionCards>

        {openRegion && (
          <RegionDetails 
            regionKey={openRegion} 
            details={details}
            onDetailsChange={onDetailsChange}
            solutionKey={solutionKey}
            questionKey={questionKey}
            countries={regionData[openRegion]}
            hideImport={hideImport}
            onClose={() => { this.closeRegion() }} />
        )}

      </section>
    ) 
  }
} 
export default EuroconnectShipmentsInput
