import React from 'react'
import cn from 'classnames'

import './ToggleInput.css'

const ToggleInput = ({
  onClick,
  trueLabel = 'Ja',
  falseLabel = 'Nej',
  value
}) => {
  return (
    <div
      className={cn('toggle-input', {
        'toggle-input--active': value
      })}
    >
      <span className="toggle-input__value toggle-input__value--value-1">
        {falseLabel}
      </span>
      <button
        className="toggle-input__button"
        type="button"
        onClick={e => {
          e.preventDefault()
          onClick(!value)
        }}
      />
      <span className="toggle-input__value toggle-input__value--value-2">
        {trueLabel}
      </span>
    </div>
  )
}

export default ToggleInput
