import React, { useState } from 'react'
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton'

import getExtra from '../../utils/getExtra'
import ExtraProduct from '../ExtraProduct/ExtraProduct'

import './ProductExtras.css'
import MediumDivider from '../MediumDivider/MediumDivider'

function isSelectedExtra(selectedExtras, solutionKey, extraKey) {
  return (
    selectedExtras &&
    Array.isArray(selectedExtras[solutionKey]) &&
    selectedExtras[solutionKey].includes(extraKey)
  )
}

const ProductExtras = ({ solution, onAddExtras, selectedExtras }) => {
  const [ expanded, setExpanded ] = useState(false)
  const hasRecommendedExtras = solution.suggestedExtras.length
  const hasMoreExtras = solution.extras.length
  
  if (!hasRecommendedExtras && !hasMoreExtras) return null

  return (
    <section className="product-extras">
      <div className="product-extras__grid">
        {solution.suggestedExtras.map(extraKey => {
          const extra = getExtra(extraKey)
          return (
            <ExtraProduct
              key={extraKey}
              extra={extra}
              solution={solution}
              onAddExtras={onAddExtras}
              isSelected={isSelectedExtra(
                selectedExtras,
                solution.key,
                extra.key
              )}
            />
          )
        })}
      </div>

      {hasMoreExtras ? <React.Fragment>
        {expanded &&
          <div className="product-extras__grid">
            {solution.extras.map(extraKey => {
              const extra = getExtra(extraKey)
              return (
                <ExtraProduct
                  key={extraKey}
                  extra={extra}
                  solution={solution}
                  onAddExtras={onAddExtras}
                  isSelected={isSelectedExtra(selectedExtras, solution.key, extra.key)}
                  />
                )
              })}
          </div>}
        <ShowMoreButton onClick={() => { setExpanded(!expanded) }} isShowingMore={expanded} />
      </React.Fragment> : null}

      <MediumDivider />
    </section>
  )
}

export default ProductExtras
