import React from 'react'
import cn from 'classnames'
import './TextInput.css'

const TextInput = ({ className, suffix, ...extraProps }) =>
  <div className="text-input-wrapper">
    <input className={cn('text-input', className)} {...extraProps} />
    {suffix && (<div className="text-input__suffix">{suffix}</div>)}
  </div>

export default TextInput
