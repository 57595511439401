import React from 'react'
import { get } from '../../utils/get-prop'
import TextInput from '../TextInput/TextInput'

const FollowUpTextInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details
}) => {
  const currentValue = get(details, `${solution.key}.${questionKey}`)

  return (
    <div style={{ textAlign: 'center' }}>
      <TextInput
        value={currentValue}
        placeholder="Fritext"
        onChange={e => {
          onDetailsChange(solution.key, questionKey, e.target.value)
        }}
      />
    </div>
  )
}

export default FollowUpTextInput
