import React from 'react'
import { get } from '../../utils/get-prop'
import allValues from '../../data/product-types-input-data.json'
import ProductTypeExtraInfoInput from '../ProductTypeExtraInfoInput/ProductTypeExtraInfoInput'
import './ProductTypesInput.css'

import OptionCards from '../OptionCards/OptionCards'
import OptionCard from '../OptionCard/OptionCard'

const ProductTypesInput = ({
  solution,
  questionKey,
  onDetailsChange,
  details,
  options = []
}) => {
  const currentValue = get(details, `${solution.key}.${questionKey}`, [])
  const values = allValues.filter(v => options.includes(v.key))

  if (values.length === 0)
    return <div>No productTypes added in solutions.json</div>

  return (
    <div className="productsCards">
      <OptionCards>
        {values.map(({ key, value, extraInfo }) => {
          const isSelected = currentValue && currentValue.includes(key)

          return (
            <OptionCard
              key={key}
              onClick={() => {
                let newValue = currentValue
                if (newValue.includes(key)) {
                  newValue.splice(newValue.indexOf(key), 1)
                } else {
                  newValue.push(key)
                }

                onDetailsChange(solution.key, questionKey, newValue)
              }}
              icon={`product-type--${key}`}
              isActive={isSelected}
              text={value}
              >
              {isSelected &&
                Array.isArray(extraInfo) &&
                extraInfo.map(ei =>
                  <ProductTypeExtraInfoInput
                    key={ei.key}
                    extraInfo={ei}
                    currentValue={get(
                      details,
                      `${solution.key}.${questionKey}:${key}:${ei.key}`
                    )}
                    onChange={value => {
                      onDetailsChange(
                        solution.key,
                        `${questionKey}:${key}:${ei.key}`,
                        value
                      )
                    }}
                  />
                )}
            </OptionCard>
          )
        })}
      </OptionCards>
    </div>
  )
}

export default ProductTypesInput
