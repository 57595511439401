import React, {  useState } from 'react'
import cn from 'classnames'
import './ContactCard.css'
import userProfileFallback from '../../assets/profile-fallback.png'

const ContactCard = ({ user }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <React.Fragment>
      <button
        style={{ display: 'none' }}
        type="button"
        className="contact-card-icon"
        onClick={() => { setExpanded(!expanded) }}
      >
        <img src={user.image || userProfileFallback} alt="User profile" />
      </button>

      <div
        className={cn('contact-card', {
          'contact-card--hidden': !expanded
        })}
      >
        <img
          src={user.image || userProfileFallback}
          className="contact-card__image"
          alt="User profile"
        />
        <div className="contact-card__name">
          {user.name}
        </div>
        <div className="contact-card__title">
          {user.title}
        </div>
        <div className="contact-card__email">
          {user.email}
        </div>
        <div className="contact-card__phone">
          {user.phone}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContactCard
