import React from 'react'
import shippingItems from '../../data/shipping-items.json'
import isEnabledFilter from '../../utils/isEnabledFilter'
import OptionCards from '../OptionCards/OptionCards'
import OptionCard from '../OptionCard/OptionCard'
import './ShippingItemsPicker.css'
const filterName = 'shippingItems'

const ShippingItemsPicker = ({ filters, onAddFilter }) =>
  <OptionCards>
    {shippingItems.map(({ key, value }) =>
      <OptionCard
        key={key}
        icon={`shipping-items-icon-${key}`}
        onClick={() => {
          onAddFilter(filterName, key)
        }}
        isActive={isEnabledFilter(filters, filterName, key)}
        text={value}
      />
    )}
  </OptionCards>

export default ShippingItemsPicker
